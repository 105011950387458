// Import the functions you need from the SDKs you need

export const environment = {
    production: true
};
  
export const firebaseConfig = {
    apiKey: "AIzaSyBXsInzxD8_AljOn-eRlCxmBoYev5Q_9nE",
    authDomain: "thresher-364620.firebaseapp.com",
    projectId: "thresher-364620",
    storageBucket: "thresher-364620.appspot.com",
    messagingSenderId: "882442714662",
    appId: "1:882442714662:web:c71b8a7a445c8399d1b0e7",
    measurementId: "G-Y8NJENTF1W"
};

export const coolBeans = {
    bimBam: "AIzaSyDmWoueUpo8gHsri9zNueSm3rYWSHtR9l8"
};

export const recaptchav2 = {
    siteKey:'6LfTUBIgAAAAAJnrGcltCwd29Y8VF_YSHrKGzVyy',
    secretKey:'6LfTUBIgAAAAAOm6hSEHDthZyblkNpYyM6x9Qu3E',
};

export const recaptchav3 = {
    siteKey:'6LeBfAcgAAAAAF5lzYGvM_iIVUy6FqAt5wsim01g',

};
