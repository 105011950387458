<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container"> 
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== #182957; -->
    <mat-toolbar color="primary" style="background: linear-gradient(45deg, #1a85ff, #d41159);" class="topbar telative">
        <!-- <b style="padding-left:3em;">TQP</b> -->
        <!-- src/assets/QuakerAnalytics_Logo_KO.png -->
        <!-- <img style="padding-top: 2px; margin-top: 2px;" height="44px" src="assets/images/TheQuakerProject_IconOnly_Logo_KO_gradient.png" alt="homepage" class="dark-logo"> -->
        <img style="padding-top: 2px; margin-top: 2px;" height="44px" src="assets/QuakerAnalytics_Logo_KO.png" alt="homepage" class="dark-logo">
        <div style="margin-left: 6px; white-space: normal; display: inline-block;width: 61px; margin-top: 5px; line-height: 17px;font-size: 15px;color: #a12000;font-family: helvetica, sans-serif;">
            <!-- <div style="color: #a32136;font-size:13px; font-family: Helvetica, sans-serif ;">
            {{companyName}}
            </div>  -->
            <!-- <div><span style="word-spacing:inherit">{{companyName}}</span></div> -->
            <!-- <span>Quaker Analytics</span> -->
            <!-- <span style="display: block; color: #a32136;font-size:13px; font-family: Helvetica, sans-serif ;">QUAKER</span> -->
            <!-- <span style="display: block; color: #a32136;font-size:13px; font-family: Helvetica, sans-serif ;">PROJECT</span>     -->
        </div>
        <!-- ============================================================== -->
        <!-- Logo - style you can find in header.scss -->
        <!-- ============================================================== -->
        <mat-form-field class="insurance-product-select" appearance="outline">
            <!-- <mat-label>Select an option</mat-label> -->
            <mat-select [disabled]="true" value="commercial_auto" style="color: #666666;">
              <!-- <mat-option>None</mat-option> -->
              <mat-option value="commercial_auto">Commercial Auto</mat-option>
              <mat-option value="g_and_l">General Liability</mat-option>
              <mat-option value="workers_comp">Workers Comp</mat-option>
              <!-- <mat-option value="option3">Option 3</mat-option> -->
            </mat-select>
        </mat-form-field>
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <!-- Logo icon --><b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <!-- <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo"> -->
                    <!-- Light Logo icon -->
                    <!-- <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo"> -->
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <span fxShow="false" fxShow.gt-xs>
                 <!-- dark Logo text -->
                 <!-- <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo"> -->
                 <!-- Light Logo text -->    
                 <!-- <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage"> -->
                </span> </a>
                <button mat-icon-button (click)="snav.toggle()" style="margin-right: 156px; color: #f7f6f2" value="sidebarclosed">
                    <mat-icon>menu</mat-icon>
                </button>
        </div>
        <!-- ============================================================== -->
        <!-- sidebar toggle -->
        <!-- ============================================================== -->
        
       
        <span fxFlex></span>

        <!-- Logout button -->
        <button mat-button (click)="logout()" style="margin-left: auto; color: #f7f6f2">
            <mat-icon>exit_to_app</mat-icon>
            Logout
        </button>

        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <app-header></app-header>
    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ====================DONT DELETE VVVV========================================== -->
        <mat-sidenav #snav id="snav" class="dark-sidebar pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches"  >
            
                <app-sidebar></app-sidebar>
               
            
        </mat-sidenav>
        <!-- =====================DONT DELETE ^^^^^========================================= -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper">
                 
                <div class="page-content">
                   
                        <router-outlet><app-spinner></app-spinner></router-outlet>
                    
                </div>    
               
        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>

<style>
    .insurance-product-select {
        width: 162px;
        font-size: 11px;
        margin-top: 15px;
        margin-left: 35px;
        /* color: darkslategrey; */
    }
    ::ng-deep .mat-form-field-underline {
        display: none;
    }
    ::ng-deep .mat-icon-button:hover {
        background-color: transparent;
    }
    
</style>