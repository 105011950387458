import {ReportItem} from '../models/report-item';
import {VenueVolatalityItem} from '../models/venue-volatality-item';
import { areaChartCacheItem } from '../models/area-chart-cache-item';
import { Injectable } from '@angular/core';
import { 
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/compat/firestore';
import { map, concatMap, switchMap, share, tap, first, take, filter, mergeAll, toArray,  } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
// import * as firebase from 'firebase/compat/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { InjuryPredictionResult } from '../models/injury-prediction-result';
import { RecentReviewListItem } from '../models/most-recent-reviewlist-item';
import { AuditClaimantReportItem } from '../models/audited-claimant-report';
import { PredictionGroupConfig } from '../models/prediction-group-config';
import { UserRoles } from '../models/user-roles';
import { UserData } from '../models/user-data';
import { HelperService } from './helper-service';
import { ReviewListHistoryItem } from '../models/reviewlist-history-item';

@Injectable({
    providedIn: 'root'
  })

  export class DataService {
    reportsCollection:AngularFirestoreCollection<ReportItem>;
    subscriptions: any[] = [];
    recordLimit = 1;
    reportsPath = 'tabs';
    adjustorsPath = 'adjustors';
    clmtReportsPath = 'claimant-reports';
    recentClmtReportsPath = 'recent-claimant-reports';
    venueVolatalityPath = 'states-venue-volatality';
    // reportsPath = 'demo-reports';
    venueVolatalityPathForStateCounties = 'states-counties-venue-volatality';
    private reviewsPath = 'reviews';
    lemmPath = 'loss-adjusted-expense-calc';
    requestedReviewsPath = 'requested-reviews';  
    ref = this.afs.collection("reports");
    watchlistParentCollection = 'most-recent-watchlist';
    watchlistSubCollection = 'user-recent-watchlist-view';
    stateCityCountyCollection = 'state-city-counties-collection';
    stateCityCountySubCollection = "state-city-county-sub-collection";
    injuryPredictionResultsCollection = 'injury-code-prediction-results'; 
    mostRecentReviewPath = "most-recent-reviewlist";
    auditedClmtReportsPath = 'audited-claimant-reports';
    auditedReviewPath = "reviewlist-history-records";
    tabRef: AngularFirestoreCollection<any>;
    venueVolatalityRef: AngularFirestoreCollection<any>;
    reviewRef:any;
    currentTab: any;
    reportsRef: any;

    readonly orgConfigCollectionPath = 'config';

    constructor(
      private afs: AngularFirestore,
      private helperService: HelperService
    ) {
      this.tabRef = this.afs.collection(this.reportsPath, (ref) => {
        return ref.orderBy('createdAt', 'desc');
      });

      // this.venueVolatalityRef = this.afs.collection(this.venueVolatalityPath, ref => ref.orderBy('createdAt', 'desc').limit(1));

      this.reportsRef = this.tabRef
      .valueChanges({ idField: 'id' })
      .pipe(
        take(1),
        switchMap( (ref: any) => 
          // this.afs.collection(`demo-reports/${ref[0].id}/reports`, 
          this.tabRef.doc(`${ref[0].id}`).collection(`reports`, 
          ref => ref.orderBy(`createdAt`, `desc`).limit(10) ).valueChanges() ),
        mergeAll(),
        filter( (report: any) => report.reviewStatus === false),
      );      
    }

    getRecentClaimantReports(filteredClaims) {
        let query = this.afs.collectionGroup(
            'recent-claimant-reports', 
            (ref) => {
                let queryRef = ref;
                let orderBy = 'claimantInfo.claim';
                queryRef = queryRef.where('claimantInfo.claim','in',filteredClaims);
                queryRef = queryRef.orderBy(orderBy, 'asc');
                queryRef = queryRef.orderBy('updatedAt', 'desc');
                return queryRef;
            });
        return query;
    }

    getRecentClaimantReportsBasedOnPredictionRange(filterRange) {
      const filter_vals = filterRange.split("-",3);
      let filterMin = +filter_vals[0]*1000;
      let filterMax = +filter_vals[1]*1000;
      let query = this.afs.collectionGroup('recent-claimant-reports', (ref) => {
      let queryRef = ref;
      let orderBy = 'facts.predictionRange.min';
      queryRef = queryRef.where('facts.predictionRange.min','>=',filterMin);
      queryRef = queryRef.where('facts.predictionRange.min','<=',filterMax);
      queryRef = queryRef.orderBy(orderBy, 'asc');
      queryRef = queryRef.orderBy('updatedAt', 'desc');
      return queryRef;
      });
      return query;
    }

    getOrgGroups() {
        // this.afs.collection(this.venueVolatalityPath, ref => ref.orderBy('createdAt', 'desc').limit(1));
        return this.afs.collectionGroup(`organization`);

        //     const config$ = this.afs.collection(
        //     this.orgConfigCollectionPath,
        //     ref => ref.orderBy('createdAt', 'desc').limit(1),
        // );
        // config$
        //     .valueChanges({idField: 'id' })
        //     .pipe(
        //         tap(t => {
        //             console.log(`GETTING ORG GROUPS SERVICE....`);
        //             console.log(t);
        //             console.log(`GETTING ORG GROUPS SERVICE....`);
        //         }),
        //         concatMap(config => {
                    
        //             const id = config[0]['id'];
        //             console.log(`config length: ${config.length}`);
        //             console.log(`config doc id: ${id}`);
        //             this.afs.collection(
        //                 `${this.orgConfigCollectionPath}/${id}`,
        //                 ref => ref.orderBy('createdAt', 'desc').limit(1),
        //             );
        //             // this.afs.doc(id).collection(`organization`);
        //             return config;
        //         }),
        //     ).subscribe();

        // return this.afs.collection(
        //     this.orgConfigCollectionPath,
        //     ref => ref.orderBy('createdAt', 'desc').limit(1),
        // );
    }
    
    generateNewReportRequest(newReportPayload) {
      const serverTimestamp = this.getTimestamp();
      newReportPayload.requestStatus = 'todo';
      newReportPayload.createdAt = serverTimestamp;
      newReportPayload.updatedAt = serverTimestamp;
      newReportPayload.requestType = 'generate-claimant-report';
      return this.afs.collection(`requests`).add(newReportPayload);
    }
    
    getTimestamp() {
      return firebase.firestore.FieldValue.serverTimestamp();
    }

    getNextPreviousCommon(direction, pageLimit){
      this.reportsCollection = this.afs.collection("demo-reports", (ref) =>
        ref.orderBy('createdAt', 'desc').limit(1)
      );
    }

    getRequestedReviewRecord(claimId){
      return this.afs.collection(this.requestedReviewsPath, (ref) => ref.where('claimId', '==', claimId)).get();
    }

    getMostRecentWatchListItems(userId) {
      return this.afs.collection(this.watchlistParentCollection).doc(userId).collection(this.watchlistSubCollection);
    }

    getMostRecentUserWatchlistItems(uid) {
      return this.getMostRecentWatchListItems(uid)
      .valueChanges()
      .pipe(
        map(recentWatchlist => recentWatchlist[0]),
        map(recentWatchlist => {
          let claimIds = [];
          if (recentWatchlist) {
            recentWatchlist['claims'].forEach((claim) =>{
              claimIds.push(claim);
            });
          }
          return claimIds;
        }),
        );
    }

    /**
     * Return reviewlist history items based on user roles.
     * Super manager - return all reviewlist history items
     * Manager - return all reviewlist history items
     * Adjustor - return reviewlist items that adjustor verified
     * @param {string} userUid - User uid can be found in auth service
     * @param {string} userRoles - User roles can be found in auth service
     */
    getReviewDoneItems(userUid: string, userRoles: UserRoles) {
      const reviewlistHistoryPath = this.auditedReviewPath;
      return this.afs
        .collection(reviewlistHistoryPath,
          (ref) => {
              let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
              query = this.helperService.queryReviewDoneByUserRole(query, userUid, userRoles);
              return query;
          }
        ).valueChanges()
           .pipe(
                map(data => data ? data.map(report => report as ReviewListHistoryItem) : [])
            );
    }

    /**
     * Return reviewlist items based on user roles.
     * Super manager - return all reviewlist items
     * Manager - return reviewlist items that manager is the one who added
     * Adjustor - return reviewlist items that tie to adjustor
     * @param {string} userUid - User uid can be found in auth service
     * @param {string} userRoles - User roles can be found in auth service
     */
    getUnderReviewItems(userUid: string, userRoles: UserRoles) {
      const recentReviewlistPath = this.mostRecentReviewPath;
      return this.afs
        .collection(recentReviewlistPath,
          (ref) => {
              let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
              query = this.helperService.queryUnderReviewByUserRole(query, userUid, userRoles);
              return query;
          }
        )
        .valueChanges()
        .pipe(
          map(data => data ? data.map(report => report as RecentReviewListItem) : [])
        );
    }

    /**
     * Return auditlist items based on user roles.
     * Super manager - return all auditlist items
     * Manager - return auditlist items from their offices
     * Adjustor - return auditlist items that adjustor verified
     * @param {string} userUid - User uid can be found in auth service
     * @param {string} userRoles - User roles can be found in auth service
     */
    getAuditlistItems(userUid: string, userRoles: UserRoles, orgGroups: Array<string>) {
      const auditedPath = this.auditedClmtReportsPath;
      return this.afs
        .collection(auditedPath,
          (ref) => {
              let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
              query = this.helperService.queryAuditlistByUserRole(query, userUid, userRoles, orgGroups);
              return query;
          }
        )
        .valueChanges()
        .pipe(
          map(data => data ? data.map(report => report as AuditClaimantReportItem) : [])
        );
    }

    // TODO: the same query that be using in prediction group component
    /**
     * Return a list of reports that newly flagged from last run based on user roles.
     * Super manager - return all newly flagged reports.
     * Manager - return newly flagged reports only from  their office.
     * Adjustor - return newly flagged reports that assigned to them from their office.
     * @param {string} predictionGroupConfig - prediction group config
     * @param {string} userRoles - User roles can be found in auth service
     * @param {string} userData - User data can be found in auth service
     */
    getNewPredictionGroupReports(predictionGroupConfig: PredictionGroupConfig, userRoles: UserRoles, userData: UserData) {
      return this.afs
        .collection(
          predictionGroupConfig.collectionPath,
          (ref) => {
            let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            // v1 without sorting
            // query = query.where('facts.predictionRange.min', '<=', this.predictionGroupConfig.max);
            // query = query.where('facts.predictionRange.min', '>=', this.predictionGroupConfig.min);
            // query = query.limit(8);

            // v2 with sorting
            const minThresholdsStr = this.helperService.convertToStringArray(predictionGroupConfig.minThresholds);
            query = query.where('facts.predictionValue.currentInjuryCode.min', 'in', minThresholdsStr);
            query = query.orderBy('claimantInfo.office', 'asc');
            query = query.orderBy('claimantInfo.claim', 'asc');
            query = this.helperService.queryOrgGroupsByUserRole(query, userRoles, userData.orgGroups, userData.uid);

            return query;
          },
        )
        .valueChanges()
        .pipe(
          map(data => data ? data.map(report => report as ReportItem) : [])
        ); 
    }

    createUpdateWatchlistRecord(loadClaims, uid) {
        const parentWatchlistRef = this.afs.collection(this.watchlistParentCollection).doc(uid);
        return parentWatchlistRef.collection(this.watchlistSubCollection, ref =>
        ref.where('uid', '==', uid).limit(1))
        .get()
        .pipe(
          switchMap(querySnapshot => {
            let docId = undefined;
            if (querySnapshot.size > 0) { 
              docId = querySnapshot.docs[0].id;
            }
              return parentWatchlistRef.collection(this.watchlistSubCollection)
                .doc(docId)
                .set({
                  'claims': loadClaims,
                  'uid': uid,
                  'watchlistViewName': 'userCurrentView',
                  'updatedAt': this.getTimestamp(),
                  'createdAt': this.getTimestamp(),
                });
          })
        );
    }

    addWatchlistItems(uid, claimants) {
      const watchlistSubRef = this.afs
        .collection(this.watchlistParentCollection)
        .doc(uid)
        .collection(this.watchlistSubCollection, ref => ref.where('uid', '==', uid).limit(1))
        .get()
        .pipe(
          switchMap(querySnapshot => {
            let docId = undefined;
            const newPayload = {
              'claims': firebase.firestore.FieldValue.arrayUnion(...claimants as string),
              'uid': uid,
              'watchlistViewName': 'userCurrentView',
              'updatedAt': this.getTimestamp(),
              'createdAt': this.getTimestamp()
            }
            // Update existing doc
            if (querySnapshot.size > 0) { 
              delete newPayload.createdAt;
              return querySnapshot.docs[0].ref.update(newPayload);
            //  Create new doc
            } else {
              return this.afs
                .collection(this.watchlistParentCollection).doc(uid)
                .collection(this.watchlistSubCollection).doc()
                .set(newPayload);
            }
          })
        );
      return watchlistSubRef;
    }

    formatReportItem(sourceData){
        const reportItem = new ReportItem();
        reportItem.claimantInfo = sourceData.claimantInfo;
        reportItem.createdAt = sourceData.createdAt;
        reportItem.docId = sourceData.docId;
        reportItem.reviewStatus = sourceData.reviewStatus;
        reportItem.updatedAt = sourceData.updatedAt;
        reportItem.facts = sourceData.facts;
        reportItem.reportTextTurns = sourceData.reportTextTurns;
        return reportItem;
    }

    formatRecentReviewItem(sourceData) {
      const recentReviewItem = new RecentReviewListItem();
      recentReviewItem.uid = sourceData.uid;
      recentReviewItem.claim = sourceData.claim;
      recentReviewItem.createdAt = sourceData.createdAt;
      recentReviewItem.reviewBatchId = sourceData.reviewBatchId;
      recentReviewItem.updatedAt = sourceData.updatedAt;
      recentReviewItem.recentReviewRecordId = sourceData.recentReviewRecordId;
      recentReviewItem.auditlistId = sourceData.auditlistId;
      return recentReviewItem;
    }

    formatVenueItem(sourceData){
      const venueVolatalityItem = new VenueVolatalityItem();
      venueVolatalityItem.currentInjury = sourceData.currentInjury;
      venueVolatalityItem.medianPaidLoss = sourceData.medianPaidLoss;
      venueVolatalityItem.state = sourceData.state;
      venueVolatalityItem.county = sourceData.county;
      venueVolatalityItem.venueVolatility = sourceData.venueVolatility;
      return venueVolatalityItem;
    }
    
    formatAreaChartCacheItem(sourceData){
      const areaChartCacheItemR = new areaChartCacheItem();
      areaChartCacheItemR.county = sourceData.county; 
      areaChartCacheItemR.state = sourceData.state;
      areaChartCacheItemR.day1 = sourceData.day1;
      areaChartCacheItemR.day2 = sourceData.day2;
      areaChartCacheItemR.day3 = sourceData.day3;
      areaChartCacheItemR.injury = sourceData.injury;
      areaChartCacheItemR.result_1 = sourceData.result_1;
      areaChartCacheItemR.result_2 = sourceData.result_2;
      areaChartCacheItemR.result_3 = sourceData.result_3;
      return areaChartCacheItemR;
    }

    formatInjuryPredictionResultItem(sourceData) {
      const injuryPredictionResult = new InjuryPredictionResult();
      injuryPredictionResult.claimNumber = sourceData.claimNumber;
      injuryPredictionResult.clmtList = sourceData.clmtList;
      injuryPredictionResult.targets = sourceData.targets; 
      injuryPredictionResult.createdAt = sourceData.createdAt;
      injuryPredictionResult.updatedAt = sourceData.updatedAt;
      injuryPredictionResult.claims = injuryPredictionResult.parseClaims(); 
      injuryPredictionResult.minimumInjuryPredicted = injuryPredictionResult.parseMinimumInjuryPredicted();
      return injuryPredictionResult;
    }

    formatAuditRecorditem(report, reviewHistory) {
      const auditedClaimantReportItem = new AuditClaimantReportItem();
      auditedClaimantReportItem.claim = reviewHistory.claim;
      auditedClaimantReportItem.createdAt = reviewHistory.createdAt;
      auditedClaimantReportItem.updatedAt = reviewHistory.updatedAt;
      auditedClaimantReportItem.auditlistId = reviewHistory.auditlistId;
      auditedClaimantReportItem.verifiedBy = reviewHistory.verifiedBy;
      auditedClaimantReportItem.reviewStartedAt = reviewHistory.reviewStartedAt;
      auditedClaimantReportItem.adjustor = reviewHistory.adjustor;
      auditedClaimantReportItem.office = reviewHistory.office;
      auditedClaimantReportItem.auditedAttributes.lossReserve = report.claimantInfo.lossReserve;
      auditedClaimantReportItem.auditedAttributes.injuryCode = report.claimantInfo.injuryCode;
      auditedClaimantReportItem.auditedAttributes.predictionMin = report.facts.predictionRange.min;
      auditedClaimantReportItem.auditedAttributes.lae = report.tools;
      return auditedClaimantReportItem.toFirestoreObject();
    }

    getMostRecentReports() {
      this.reportsCollection = this.afs.collection(this.reportsPath, ref => ref.orderBy('createdAt', 'desc').limit(1));
      return this.reportsCollection.snapshotChanges()
          .pipe(
            map((ref) => {  
              return this.afs.collection(this.reportsPath).doc(ref[0].payload.doc.id).collection('reports');
            }),
            switchMap(subRef => subRef.valueChanges().pipe(map(reports => reports.map(data => this.formatReportItem(data))))));
    }

    // get previous report where minimum prediction value is less than current min prediction and injury code is different 
    getPreviousReportWithDiffPrediction(claimNumber: string, injuryCode: string, minPrediction: number, updatedAt: firebase.firestore.Timestamp) {
      return this.afs.collectionGroup(this.clmtReportsPath, (ref) => {
        let query: firebase.firestore.Query = ref;
        query = query.where('claimantInfo.claim', '==', claimNumber)
                    .where('updatedAt', '<', updatedAt)
                    .orderBy('updatedAt', 'desc')
                    .limit(1);
        return query;
      }).valueChanges()
      .pipe(
        take(1),
        map(results => 
          results.map((result: ReportItem) => {
            const higherPrediction = result.facts.predictionRange.min > minPrediction;
            const differentInjury = result.claimantInfo.injuryCode !== injuryCode;
            if (higherPrediction && differentInjury) {
              return this.formatReportItem(result);
            }
          })
        )
      );
    }

    getVenueVolatilityData(docId, queryParams) {
      return this.afs
        .collection(this.venueVolatalityPathForStateCounties)
        .doc(docId)
        .collection("volatality-info", (venueRef) => {
          let query: firebase.firestore.Query<firebase.firestore.DocumentData> = venueRef;
          for (const field in queryParams) {            
            query = (Array.isArray(queryParams[field])) ? query.where(field, 'in', queryParams[field]) : query.where(field, '==', queryParams[field]);
          }
          return query;
        }).get().pipe(map(venueInfo => venueInfo.docs.map(venue => this.formatVenueItem(venue.data()))));  
    }

    getVenueVolatalityDataWithoutNational(docId,currInjury,venueVolatality) {
      return this.afs
        .collection(this.venueVolatalityPathForStateCounties)
        .doc(docId)
        .collection("volatality-info", (venueRef) => {
          let query: firebase.firestore.Query<firebase.firestore.DocumentData> = venueRef;
          query = query.where("state", "!=", "national")
          .where("currentInjury", "==",currInjury)
          .where("venueVolatility", "==", venueVolatality);
          return query;
        }).get().pipe(map(venueInfo => venueInfo.docs.map(venue => this.formatVenueItem(venue.data()))));
    }

    getStateCityCountyCollectionRecord(state, city) {
      return this.afs.collection(
        this.stateCityCountyCollection, 
        ref => ref.orderBy('createdAt', 'desc').limit(1)
      ).get().pipe(
        take(1),
        map(m =>m.docs[0].id),
        switchMap((id) => {
          return this.afs
          .collection(this.stateCityCountyCollection)
          .doc(id)
          .collection(this.stateCityCountySubCollection, (cityCountySub) => {
            return cityCountySub
            .where(`city_name`, `==`,city)
            .where(`state_name`, `==`,state);
        }).valueChanges()
    }));
    }

    getInjuryPredictionResult(claimId: string): Observable<InjuryPredictionResult | any> {
      return this.afs
      .collection(this.injuryPredictionResultsCollection, (ref) => {
        let query: firebase.firestore.Query<firebase.firestore.DocumentData> = ref;
        query = query.orderBy('updatedAt', 'desc')
          .where('claimNumber', '==', Number(claimId))
          .limit(1);
        return query
      })
      .valueChanges()
      .pipe(
        take(1),
        map(results => results.map(result => this.formatInjuryPredictionResultItem(result)))
      )
    }

    getInProgressAuditRecord(claimId) {
      return this.afs.collection(this.mostRecentReviewPath, (ref) =>
        ref
        .where("claim", "==", claimId)
        .where("auditlistId", "!=", "")
      )
      .valueChanges();
    }
  
    getAuditedRecord(claimId) {
      return this.afs.collection(this.auditedReviewPath, (ref) =>
        ref
        .where("claim","==",claimId)
        .where("auditlistId", "!=", "")
        )
        .valueChanges();
    }

    getAttorneyInfo(claimant: ReportItem) {
        let colPath = `/plaintiff-attorney/wsQHT1axFWu86gZMowpU/recent-attorney-records`;

        return this.afs.collection(
            colPath, 
            (ref) => {
                let query: firebase.firestore.Query = ref;
                query = query.where("claim", "==", claimant.claimantInfo.claim);
                query = query.limit(1);
                return query;
            });
    }

    ngOnDestroy(): void {
      this.subscriptions.forEach(element => {
        element.unsubscribe();
      });
    }
  }
  